/* Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap");

/* Targets all elements */
* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  /* removes all the bullet on li */
  list-style: none;
  /* removes all anchor tags decoration */
  text-decoration: none;
}

/* CSS variables */
:root {
  --color-bg: #1f1f1f;
  --color-bg-variant: #2b2b2b;
  --color-primary: #24d0dc;
  --color-primary-variant: rgba(36, 208, 220, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --font-body: "Montserrat", sans-serif;
  --font-heading: "Maven Pro", sans-serif;

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  /* --box-shadow: 20px 35px 30px 0px rgba(0, 0, 0, 0.4);  */
  --box-shadow: 5px 12px 8px 0px rgba(0, 0, 0, 0.25);
  --border-radius: 0.5rem;

  /* Animation - Load and Delay */
  --big-load: 600ms;
  --small-load: 300ms;
  --big-delay: 300ms;
  --small-delay: 150ms;
}

html {
  /* smooth scroll through the page */
  scroll-behavior: smooth;
}

/* removes scrollbar */
::-webkit-scroll {
  display: none;
}

body {
  font-family: var(--font-body);
  color: var(--color-white);
  line-height: 1.7;
  background: var(--color-bg);
}

/* General Styles ------------------------------- */

.container {
  width: var(--container-width-lg);
  /* Positions it in the middle horizontally */
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
  font-family: var(--font-heading);
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
}

h5 {
  font-weight: 200;
  font-size: 1rem;
}

section {
  /* margin-top: 12rem; */
  padding-top: 12rem;
  /* height: 100vh; */
}

/* Direct sections to the heading */
section > .container > h2,
section > .container > h5 {
  text-align: left;
  color: var(--color-light);
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > .container > h2,
section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  /*max-content will not wrap at all even if it causes overflows  */
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  /* Changes border color to transparent */
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-white);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* Animation  ------------------------------- */

/* Content */
.panel-animate {
  animation: content-load var(--big-load) ease-in-out var(--big-delay);
  animation-fill-mode: forwards;
  transform: translateY(50px);
}

/* Headings */
.heading-animate {
  animation: content-load var(--small-load) ease-in-out
    calc(var(--big-delay) + var(--small-delay));
  animation-fill-mode: forwards;
  transform: translateY(50px);
  opacity: 0;
}

/* Animate out */
.section-animateout {
  animation: section-loadout var(--small-load) ease-in-out;
  animation-fill-mode: forwards;
}

/* Key Frames ---------------------------- */

@keyframes content-load {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  90% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes section-loadout {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }

  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}

/* Media Queries (MD Devices etc tablets) ------------------------------- */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
    padding-top: 0;
  }
}

/* Media Queries (SM Devices etc mobile) ------------------------------- */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem;
  }

  /* Animation  ------------------------------- */

  /* Content */
  .panel-animate {
    animation: none;
    transform: translateY(0px);
  }

  /* Headings */
  .heading-animate {
    animation: none;
    transform: translateY(0px);
    opacity: 1;
  }

  /* Animate out */
  .section-animateout {
    animation: none;
  }
}
