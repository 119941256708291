.portfolio__container {
  width: 100%;
  height: 600px;
  /* Animation - Keep default at 0 opacity  */
  opacity: 0;
}

.portfolio__item {
  border-radius: var(--border-radius);
  transition: var(--transition) !important;
  font-size: 1rem;
  box-shadow: var(--box-shadow);
}

.portfolio__item:hover {
  cursor: default;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.portfolio__item-cta {
  border-radius: var(--border-radius);
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  width: 100%;
  height: 100%;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  transition: var(--transition);
}

.portfolio__item-cta:hover {
  background: #000000b0;
  border: transparent;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}

.portfolio__item-btn {
  color: var(--color-light);
  min-width: 110px;
  margin-top: 0.5rem;

  position: relative;
}

.portfolio__item-cta-icon {
  /* vertical-align: middle; */
  margin-left: 0.15rem;
  color: var(--color-primary-variant);
  font-size: 1.2rem;

  transition: var(--transition);
  position: absolute;
  top: 5px;
}

.portfolio__item-cta > .portfolio__item-btn:hover .portfolio__item-cta-icon {
  color: var(--color-primary);
  margin-left: 0.5rem;
}

/* .swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 5.5rem !important;
}

.swiper-slide:nth-child(1n),
.swiper-slide:nth-child(2n) {
  width: 30%;
}

.swiper-pagination-bullet-active {
  background: var(--color-primary) !important;
} */

.swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 5.5rem !important;
}

.swiper-slide {
  text-align: center;
  background: #fff;
  height: calc((100% - 30px) / 2) !important;

  display: flex;
  /* justify-content: center;
  align-items: center; */
}

.portfolio__menu > ul {
  display: flex;
  column-gap: 2rem;
  margin-bottom: 2rem;
}

.portfolio__menu-btn {
  cursor: pointer;
  color: var(--color-light);
  transition: var(--transition);
}

.portfolio__menu-btn.active {
  color: var(--color-white);
}

.portfolio__menu-btn:hover {
  color: var(--color-white);
}

/* Media Queries (MD Devices etc tablets) ------------------------------- */

@media screen and (max-width: 1024px) {
  /* .swiper-slide:nth-child(1n),
  .swiper-slide:nth-child(2n) {
    width: 70%;
  } */
}

/* Media Queries (SM Devices etc tablets) ------------------------------- */

@media screen and (max-width: 600px) {
  /* .swiper-slide:nth-child(1n),
  .swiper-slide:nth-child(2n) {
    width: 90%;
  } */

  .portfolio__container {
    /* No animation for mobile */
    opacity: 1;
  }
}
