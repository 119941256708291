.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  /* Animation - Keep default at 0 opacity  */
  opacity: 0;
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 2.5rem 5rem;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover {
  /* background: transparent;
  border-color: var(--color-primary-variant); */
  cursor: default;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-color: rgba(255, 255, 255, 0.1);
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 3rem;
  /* color: var(--color-primary); */
}

.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* gap between rows, no gap between column */
  /* row-gap: 2rem;
  column-gap: 2rem; */
  gap: 2rem;

  text-align: center;
}

.experience__details-icon {
  color: var(--color-primary);
  font-size: 1.4rem;
}

/* Media Queries (MD Devices etc tablets) ------------------------------- */

@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }

  .experience__container > div {
    width: 80%;
    padding: 2rem;
    /* margin: 0 auto; */
    margin: 0;
  }

  .experience__container > div h3 {
    margin-bottom: 1rem;
  }

  .experience__content {
    padding: 1rem;
  }
}

/* Media Queries (SM Devices etc tablets) ------------------------------- */

@media screen and (max-width: 600px) {
  .experience__container {
    /* Gap between front-end and professional panels */
    gap: 1rem;
    /* No animation for mobile */
    opacity: 1;
  }

  .experience__container > div {
    width: 100%;
    padding: 1rem;
  }
}
