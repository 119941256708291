header {
  height: 100vh;
  /* padding-top: 10rem; */
  /* hide, display or scroll if the content overflows its container */
  overflow: hidden;

  background: #1a1a1a;
  box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.5);
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;

  /* Center middles content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header__container-content {
  max-width: 600px;
}

.header__container-content h5 {
  animation: content-load var(--small-load) ease-in-out
    calc(var(--big-delay) + 600ms);
  animation-fill-mode: forwards;
  transform: translateY(50px);
  opacity: 0;
}

.header__container-content h1 {
  animation: content-load var(--small-load) ease-in-out
    calc(var(--big-delay) + 900ms);
  animation-fill-mode: forwards;
  transform: translateY(50px);
  opacity: 0;
}

.header__container-content p {
  animation: content-load var(--small-load) ease-in-out
    calc(var(--big-delay) + 1200ms);
  animation-fill-mode: forwards;
  transform: translateY(50px);
  opacity: 0;
}

/* CTA ------------------------- */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.cta a:first-child {
  animation: button-first-load var(--small-load) ease-in-out
    calc(var(--big-delay) + 1500ms);
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateX(-50px);
}

.cta a:last-child {
  animation: button-last-load var(--small-load) ease-in-out
    calc(var(--big-delay) + 1500ms);
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateX(50px);
}

/* Header Socials ------------------------- */

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 2rem;

  animation: left-side-content-load var(--small-load) ease-in-out
    calc(var(--big-delay) + 1500ms + var(--small-delay));
  animation-fill-mode: forwards;
  transform: translateY(50px);
  opacity: 0;
}

.header__socials a {
  font-size: 1.2rem;
}

/* .header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
} */

/* Profile Photo ------------------------- */

.profile-photo {
  width: 22rem;
  height: auto;
  margin-bottom: 4rem;

  animation: profile-photo-load var(--big-load) ease-in-out var(--big-delay);
  animation-fill-mode: forwards;
  transform: translateY(50px);
  opacity: 0;
}

.profile-photo img {
  border-radius: 12rem;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
}

.profile-photo img:hover {
  box-shadow: none;
}

/* Scroll Down ------------------------- */

.scroll__down {
  position: absolute;
  right: -3rem;
  bottom: 4.5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 1.2rem;
}

.scroll__down-content {
  animation: right-side-content-load 300ms ease-in-out
    calc(var(--big-delay) + 1500ms + var(--small-delay));
  animation-fill-mode: forwards;
  transform: translateY(50px);
  opacity: 0;
}

/* Animatation  ------------------------------- */

/* Key Frames ---------------------------- */

@keyframes profile-photo-load {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  90% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes content-load {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  90% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes button-load {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes button-first-load {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes button-last-load {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes left-side-content-load {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes right-side-content-load {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

/* Media Queries (MD Devices etc tablets) ------------------------------- */

@media screen and (max-width: 1024px) {
  /* header {
    padding-top: 7rem;
  } */
}

/* Media Queries (SM Devices etc tablets) ------------------------------- */

@media screen and (max-width: 600px) {
  header {
    padding-top: 4rem;
  }

  .header__container {
    display: block;
  }

  .profile-photo {
    width: 18rem;
    margin: 1rem auto 3rem;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}
