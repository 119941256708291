nav {
  background: rgba(0, 0, 0, 0.3);
  /* Cuts off where the content ends so it doesn't overflow */
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  /* Makes it fixed on the page and above on other elements */
  z-index: 2;
  position: fixed;
  /* Make it center */
  /* left: 50%;
  transform: translateX(-50%); */
  /* bottom: 2rem; */
  top: 2rem;
  right: 2rem;
  /* Display and gap seperates the a tags and gives a gap */
  display: flex;
  gap: 1rem;

  border-radius: 3rem;
  backdrop-filter: blur(15px);
}

nav a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  color: var(--color-light);
  font-size: 1.1rem;

  transition: var(--transition);
}

nav a:hover {
  background: rgba(0, 0, 0, 0.3);
  color: var(--color-primary);
}

nav a.active {
  background: var(--color-primary);
  color: var(--color-white);
}

nav > a > span {
  position: absolute;
  bottom: -3.5rem;
  font-size: 14px;
  color: var(--color-light);
  pointer-events: none;

  animation-fill-mode: forwards;
  transition: var(--transition);
  opacity: 0;
}

nav a:hover span {
  opacity: 1;
  bottom: -2.5rem;
}

/* Media Queries (MD Devices etc tablets) ------------------------------- */

@media screen and (max-width: 1024px) {
  nav {
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    top: initial;
    right: initial;
  }

  nav > a > span {
    top: -3rem;
  }

  nav a:hover span {
    top: -2rem;
  }
}

/* Media Queries (SM Devices etc tablets) ------------------------------- */

@media screen and (max-width: 600px) {
  nav {
    gap: 0.5rem;
  }
}
