footer {
  text-align: center;
  font-size: 0.9rem;
  margin-top: 10rem;
}

footer > .container {
  border-top: 1px solid var(--color-light);
  padding: 4rem 0;
}

footer a {
  color: var(--color-light);
  font-weight: 600;
}

footer a:hover {
  color: var(--color-primary);
}

.footer__logo {
  display: inline-block;
  margin-bottom: 4rem;
  width: 50px;
}

.footer__socials {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.footer__socials a {
  background: transparent;
  color: var(--color-light);
  padding: 0.8rem;
  display: flex;
  border-radius: 0.4rem;
  border: 1px solid var(--color-light);
}

.footer__socials a:hover {
  background: var(--color-white);
  color: var(--color-primary);
  border-color: var(--color-white);
}

.footer__copyright {
  color: var(--color-light);
  font-size: 0.8rem;
}

/* Media Queries (MD Devices etc tablets) ------------------------------- */

@media screen and (max-width: 1024px) {
  footer {
    margin-top: 5rem;
  }

  .footer__copyright {
    margin-bottom: 4rem;
  }
}

/* Media Queries (SM Devices etc tablets) ------------------------------- */

@media screen and (max-width: 600px) {
  footer {
    margin-top: 0rem;
  }
}
