.about__container {
  /*Creates grip with 2 columns  */
  display: grid;
  /* The size of the column */
  grid-template-columns: 35% 50%;
  /* Leftover gap column size */
  gap: 15%;
  /* Animation - Keep default at 0 opacity  */
  opacity: 0;
}

.about__me {
  width: 100%;
  height: 100%;
}

.about__me-image {
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: var(--transition);
  box-shadow: var(--box-shadow);
}

.about__me-image:hover {
  /* transform: rotate(0); */
  -webkit-box-shadow: none;
  box-shadow: none;
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
}

.about__card:hover {
  /* background: transparent;
  border-color: var(--color-primary-variant); */
  /* Changes cursor to standard */
  cursor: default;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-color: rgba(255, 255, 255, 0.1);
}

.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: 0.95rem;
}

.about__card small {
  color: var(--color-light);
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

/* Media Queries (MD Devices etc tablets) ------------------------------- */

@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about__me {
    width: 50%;
    /* margin: 2rem auto 4rem; */
    margin: 2rem 0 4rem;
  }

  .about__content p {
    margin: 1.5rem 0;
  }
}

/* Media Queries (SM Devices etc tablets) ------------------------------- */

@media screen and (max-width: 600px) {
  .about__container {
    /* No animation for mobile */
    opacity: 1;
  }

  .about__me {
    width: 65%;
    /* margin: 0 auto 3rem; */
    margin: 0 0 3rem;
  }

  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about__card {
    padding: 1rem;
  }

  .about__content {
    text-align: justify;
  }
}
