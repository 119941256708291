.container.contact__container {
  width: 60%;
  display: grid;
  grid-template-columns: 30% 60%;
  gap: 10%;
  /* Animation - Keep default at 0 opacity  */
  opacity: 0;
}

.contact__options {
  /* display: flex;
  flex-direction: column;
  gap: 1.5rem; */
  display: inline-block;
  width: 100%;
  height: 100%;
}

.contact__option-img {
  /* background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition); */

  border-radius: var(--border-radius);
  overflow: hidden;
  /* transform: rotate(10deg); */
  transition: var(--transition);
  box-shadow: var(--box-shadow);
}

.contact__option-img:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.contact__option-icon {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  display: inline-block;
  margin-top: 0.7rem;
  font-size: 0.8rem;
}

/* Form ---------------------- */

/* To make the inputs align down */
form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  justify-content: center;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: var(--border-radius);
  background: transparent;
  border: 1px solid var(--color-white);
  resize: none;
  color: var(--color-white);

  cursor: pointer;
}

form > button,
input,
textarea {
  font-family: var(--font-body);
}

form .form__status {
  opacity: 0;
}

form .form__status-active {
  background: #65c18c;
  padding: 0.5rem;
  text-align: center;
  display: block;
  /* Fades away after 4seconds */
  animation: formStatus 5s forwards;
}

@keyframes formStatus {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Media Queries (MD Devices etc tablets) ------------------------------- */

@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .contact__options {
    width: 60%;
    margin: 0 auto;
  }

  form {
    margin-top: 2rem;
  }
}

/* Media Queries (SM Devices etc tablets) ------------------------------- */

@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);

    /* No animation for mobile */
    opacity: 1;
  }

  form {
    gap: 1rem;
  }
}
