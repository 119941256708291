.service__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  /* Animation - Keep default at 0 opacity  */
  opacity: 0;
}

.service__card {
  background: var(--color-bg-variant);
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  height: 100%;

  padding: 3rem;
  transition: var(--transition);
  text-align: center;
}

.service__card-icon {
  color: var(--color-primary);
  font-size: 2.4rem;
  margin-bottom: 1rem;
}

.service__card:hover {
  /* background: transparent;
  border-color: var(--color-primary-variant); */
  cursor: default;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-color: rgba(255, 255, 255, 0.1);
}

.service__card h3 {
  margin-bottom: 1rem;
}

/* Media Queries (MD Devices etc tablets) ------------------------------- */

@media screen and (max-width: 1024px) {
  .service__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .service__card {
    width: 80%;
    /* margin: 0 auto; */
    margin: 0;
    padding: 2rem;
  }
}

/* Media Queries (SM Devices etc tablets) ------------------------------- */

@media screen and (max-width: 600px) {
  .service__container {
    gap: 1rem;
    /* No animation for mobile */
    opacity: 1;
  }

  .service__card {
    width: 100%;
    padding: 1rem;
  }
}
